<template>
  <footer class="pt-3">
    <footer-top />
    <footer-back-to-top />
    <footer-bottom-vue />
  </footer>
</template>

<script>
import FooterBackToTop from '@/components/footer/FooterBackToTop'
import FooterBottomVue from '@/components/footer/FooterBottom'
import FooterTop from '@/components/footer/FooterTop'

export default {
  components: {
    FooterTop,
    FooterBottomVue,
    FooterBackToTop
  }
}
</script>
<style scoped lang="scss">
footer {
  background-color: #edeeef;
}
</style>
